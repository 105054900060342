.btn-rez {
    color: #fff;
    border-color: black;
    background: $primary-color;
}

a:visited {
    color: $primary-color;
}

